<bt-navbar #navbar [title]="title" [defaultTheme]="defaultTheme">
  <div fxLayout="row" fxLayoutAlign="start center" signedInLeftAlign>
    <bt-age-queue-chips></bt-age-queue-chips>
    <bt-queue-chips *hasAccess="['admin', 'skybox_level_1']"></bt-queue-chips>
    <bt-insight-queue-chips [componentBreakpoint]="'gt-lg'" [showOrgSwitcher]="isInsightMod" class="insight-queue-chips"
                            *hasAccess="['insight_moderator', 'admin', 'skybox_level_1']"></bt-insight-queue-chips>
  </div>

  <ng-container signedInRightAlign>
    <button *hasAccess="['admin', 'verifier', 'depot', 'skybox_level_1']; denied: robotSearchOnly" [matMenuTriggerFor]="robotsMenu"
            mat-button>Robots
    </button>
    <a *hasAccess="['user', 'skybox_level_1']" mat-button routerLink="/stores">Stores</a>
    <a *hasAccess="['user']" mat-button routerLink="/organizations">Organizations</a>
    <a *hasAccess="['admin', 'phone_support', 'skybox_level_1']" mat-button routerLink="/skybox">Skybox</a>
    <button *hasAccess="['verifier', 'depot']" [matMenuTriggerFor]="manufacturingMenu" mat-button>Manufacturing</button>
    <a *hasAccess="['organization_admin']" mat-button routerLink="/users">Users</a>
  </ng-container>

  <ng-container smallScreenItems>
    <button #navMenuButton *ngIf="authService.hasCurrentUser()" mat-icon-button #navMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"
            fxHide="false" fxHide.gt-md>
      <mat-icon>menu</mat-icon>
    </button>

    <mat-menu #menu xPosition="before">
      <button *hasAccess="['admin', 'verifier', 'depot', 'skybox_level_1']; denied: robotSearchOnly" [matMenuTriggerFor]="robotsMenu"
              mat-menu-item>Robots
      </button>
      <a *hasAccess="['user', 'skybox_level_1']" mat-menu-item routerLink="/stores">Stores</a>
      <a *hasAccess="['user']" mat-menu-item routerLink="/organizations">Organizations</a>
      <a *hasAccess="['admin', 'skybox_level_1']" mat-menu-item routerLink="/skybox">Skybox</a>
      <button *hasAccess="['verifier', 'depot']" mat-menu-item [matMenuTriggerFor]="manufacturingMenu">Manufacturing</button>
      <a *hasAccess="['organization_admin']" mat-menu-item routerLink="/users">Users</a>
      <a mat-menu-item routerLink="users/profile">Profile</a>
      <button mat-menu-item (click)="navbar.signOut()">Sign Out</button>
      <mat-divider class="profile-menu-divider"></mat-divider>
      <mat-button-toggle-group name="switchTheme" [value]="navbar.currentTheme" class="theme-toggle-button-group"
                               [hideSingleSelectionIndicator]="true">
        <mat-button-toggle value="badger-light" (change)="navbar.toggleTheme($event.value)" class="theme-toggle-button"
                           matTooltip="Light Theme" [matTooltipShowDelay]="600">
          <mat-icon class="theme-toggle-icon">light_mode</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="badger-dark" (change)="navbar.toggleTheme($event.value)" class="theme-toggle-button"
                           matTooltip="Dark Theme" [matTooltipShowDelay]="600">
          <mat-icon class="theme-toggle-icon">dark_mode</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </mat-menu>

    <mat-menu #robotsMenu [overlapTrigger]="false">
      <a *hasAccess="['installer', 'user', 'verifier', 'depot', 'skybox_level_1']" mat-menu-item routerLink="/robots">Search</a>
      <a *hasAccess="['admin']" mat-menu-item routerLink="/robots/software">Software Configuration</a>
      <a *hasAccess="['admin']" mat-menu-item routerLink="/robots/robot-models">Robot Models</a>
    </mat-menu>

    <mat-menu #manufacturingMenu [overlapTrigger]="false">
      <a *hasAccess="['verifier', 'depot']" mat-menu-item routerLink="/verification/provision-robot">Provision Robot</a>
      <a *hasAccess="['verifier', 'depot']" mat-menu-item routerLink="/verification">Robot Verification</a>
      <a *hasAccess="['verifier', 'depot']" mat-menu-item routerLink="/verification/verification-suites">Verification Suites</a>
    </mat-menu>

    <ng-template #robotSearchOnly>
      <a *hasAccess="['installer', 'user', 'skybox_level_1']" mat-button routerLink="/robots">Robots search only</a>
    </ng-template>
  </ng-container>
</bt-navbar>

<main class="content-container">
  <router-outlet></router-outlet>
</main>
