import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  AuthorizationService,
  BtCommonModule,
  CanDeactivateGuard,
  CommandService,
  CustomIconService,
  DeviceService,
  DialogService,
  ErrorService,
  LifecycleService,
  ManufacturingFacilityService,
  MessageService,
  MultiUserRoomService,
  NavZoneService,
  OrganizationSelectionService,
  OrganizationService,
  PlayService,
  RobotModelService,
  RobotService,
  RoleGuard,
  ScheduleService,
  SoftwareImageService,
  StoreService,
  ThemeService,
  TimezoneService,
  TitleService,
  UserService,
  VerificationService,
  ZoneService
} from '@ng-cloud/badger-core';
import { ActionCableService } from 'angular2-actioncable';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { environment } from '../environments/environment';
import { FullCalendarModule } from '@fullcalendar/angular';
import { LibLoginConfig, LibLoginInterceptor, LibLoginModule } from '@badgertechnologies/liblogin.js';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { AngularSplitModule } from 'angular-split';
import { HotkeyModule } from 'angular2-hotkeys';

@NgModule({
  exports: [
    BrowserAnimationsModule,
    BtCommonModule,
    NgxBarcode6Module,
    HotkeyModule
  ],
  declarations: [
    AppComponent
  ],
  bootstrap: [AppComponent], imports: [BrowserModule,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    BtCommonModule,
    FullCalendarModule,
    NgxBarcode6Module,
    NgJsonEditorModule,
    AngularSplitModule,
    HotkeyModule.forRoot({ cheatSheetCloseEsc: false }),
    {
      ngModule: LibLoginModule,
      providers: [
        {
          provide: LibLoginConfig, useValue: {
            fleet: environment.apiBase,
            ...environment.keycloak
          }
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LibLoginInterceptor,
          multi: true
        }
      ]
    }], providers: [
    CanDeactivateGuard,
    RoleGuard,
    ActionCableService,
    MessageService,
    MultiUserRoomService,
    CustomIconService,
    DialogService,
    ErrorService,
    RobotService,
    LifecycleService,
    StoreService,
    UserService,
    CommandService,
    PlayService,
    OrganizationSelectionService,
    OrganizationService,
    ScheduleService,
    SoftwareImageService,
    ThemeService,
    TitleService,
    DeviceService,
    AuthorizationService,
    VerificationService,
    RobotModelService,
    ManufacturingFacilityService,
    NavZoneService,
    TimezoneService,
    ZoneService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
}
