import { AbstractApiModel } from './abstract-api-model';

export class NeuralNetImage extends AbstractApiModel<NeuralNetImage> {
  organizationId: number;
  buildNumber: string;
  versionNumber: string;
  id: number;

  deserialize(json: any): this {
    this.organizationId = json.organization_id;
    this.buildNumber = json.build_number;
    this.versionNumber = json.version_number;
    this.id = json.id;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      organization_id: this.organizationId
    });
  }
}
