import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';
import { NavZone } from '@ng-cloud/badger-core/models/nav-zone';

export class NavZoneGroupVersion extends AbstractApiModel<NavZoneGroupVersion> {
  navZones: NavZone[] = [];

  deserialize(json: any): this {
    if (!json) {
      return null;
    }

    if (json.nav_zones) {
      this.navZones = json.nav_zones.map(zone => new NavZone().deserialize(zone));
    }

    return super.deserialize(json);
  }

  serialize(): any {
    return {
      nav_zones_attributes: this.navZones.map(zone => zone.serialize())
    };
  }
}
