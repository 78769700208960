import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { ManufacturingFacility } from '../models/manufacturing-facility';
import { HasManufacturingFacility } from '../models/interfaces/has-manufacturing-facility';

@Injectable()
export class ManufacturingFacilityService extends ApiService {

  /**
   * Fetch all ManufacturingFacilities
   */
  getManufacturingFacilities(params?: any): Observable<ManufacturingFacility[]> {
    return this.list(ManufacturingFacility, 'manufacturing_facilities', params);
  }

  /**
   * Fetch ManufacturingFacility for id
   */
  getManufacturingFacility(id: number): Observable<ManufacturingFacility> {
    return this.get(ManufacturingFacility, `manufacturing_facilities/${id}`);
  }

  addManufacturingFacilities(models: HasManufacturingFacility[]): Observable<ManufacturingFacility> {
    return this.collectObservable(models, model => this.addManufacturingFacility(model));
  }

  addManufacturingFacility(model: HasManufacturingFacility): Observable<ManufacturingFacility> {
    return this.getManufacturingFacility(model.manufacturingFacilityId).pipe(tap(manufacturingFacility => model.manufacturingFacility = manufacturingFacility));
  }
}
