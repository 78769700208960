import { AbstractApiModel } from './abstract-api-model';
import { VerificationInstance } from './verification-instance';
import { VerificationFixtureSet } from './verification-fixture-set';
import { HasVerificationFixtureSet } from './interfaces/has-verification-fixture-set';

export class VerificationPlan extends AbstractApiModel<VerificationPlan> implements HasVerificationFixtureSet {
  robotId: number;
  userId: number;
  locationId: number;
  storeId: number;
  verificationFixtureSetId: number;
  verificationTestSuiteId: number;
  verificationTestSuiteName: string;
  status: VerificationPlanStatus;
  planType: string;

  verificationInstances: VerificationInstance[] = [];
  verificationFixtureSet: VerificationFixtureSet;

  deserialize(json: any): this {
    this.robotId = json.robot_id;
    this.userId = json.user_id;
    this.locationId = json.location_id;
    this.storeId = json.store_id;
    this.status = json.status;
    this.planType = json.plan_type;
    this.verificationFixtureSetId = json.verification_fixture_set_id;
    this.verificationTestSuiteId = json.verification_test_suite_id;

    if (json.verification_instances) {
      this.verificationInstances = json.verification_instances.map(inst => new VerificationInstance().deserialize(inst));
      this.verificationInstances = this.verificationInstances.sort((a, b) => a.priority - b.priority);
    }

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      robot_id: this.robotId,
      user_id: this.userId,
      location_id: this.locationId,
      store_id: this.storeId,
      status: this.status,
      plan_type: this.planType,
      verification_fixture_set_id: this.verificationFixtureSetId,
      verification_test_suite_id: this.verificationTestSuiteId
    });
  }
}

export enum VerificationPlanStatus {
  pending = 'pending',
  inProgress = 'in_progress',
  complete = 'complete',
  aborted = 'aborted'
}
