import { AbstractApiModel } from './abstract-api-model';
import { RobotModel, VerificationTask } from '@ng-cloud/badger-core';

export class VerificationTestSuite extends AbstractApiModel<VerificationTestSuite> {
  description: string;
  name: string;
  tasks: VerificationTask[] = [];
  robotModels: RobotModel[] = [];

  deserialize(json: any): this {
    this.description = json.description;
    this.name = json.name;

    if (json.robot_models) {
      json.robot_models.forEach(model => {
        this.robotModels.push(new RobotModel().deserialize(model));
      });
    }

    if (json.tasks) {
      json.tasks.forEach((task) => {
        this.tasks.push(new VerificationTask().deserialize(task));
      });
    }
    return super.deserialize(json);
  }

  serialize(): any {
    const tasks = [];
    this.tasks.forEach((task) => {
      tasks.push(task.serialize());
    });
    return Object.assign(super.serialize(), {
      description: this.description,
      name: this.name,
      tasks: tasks,
      robot_models: this.robotModels
    });
  }
}
