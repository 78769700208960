import { AbstractApiModel } from './abstract-api-model';
import { EventType, FrequencyType, RecurrenceType } from './store-schedule';
import { Play } from './play';

import { RRule, Weekday } from 'rrule';
import { DateTime } from 'luxon';

import * as momentTz from 'moment-timezone';
import * as _ from 'lodash';

export class StoreScheduleEvent extends AbstractApiModel<StoreScheduleEvent> {
  createdById: number;
  updatedById: number;

  // Event Info
  eventType: string;
  recurrenceType: string;
  duration: number;
  shelfDistanceDelta: number;
  storeScheduleId: number;

  // Play Options
  playId: number;
  playOutputs: string[];

  // Nav Zone Group Options
  navZoneGroupId: number;

  afterPlay: string;

  // Command Options
  commandType: string;
  commandParameters: any;

  // Lifecycle Options:
  lifecycleState: string;

  // Single Event Options:
  scheduledDate: Date;

  // Recurring RRule
  rruleString: string;
  rrule: RRule;
  timezone: string;

  // LOCAL ONLY OPTIONS:
  rruleFCString: string;
  rruleFC: RRule;

  insightIssues: string[];
  inspectType: string;
  startTime: string;
  endTime: string;
  frequencyType: string;
  daysOfWeek: number[];
  excludedDates: Date[] = [];
  dtstartElement: string;
  rruleElement: string;
  duplicated = false;
  duplicateId: number;

  firstRecurDate: Date;
  lastRecurDate: Date;

  deserialize(json: any): this {
    this.createdById = json.created_by_id;
    this.updatedById = json.updated_by_id;

    this.eventType = json.event_type;
    this.recurrenceType = json.recurrence_type;
    this.storeScheduleId = json.store_schedule_id;

    // Play
    this.playId = json.play_id;
    this.playOutputs = json.play_outputs;

    this.afterPlay = json.after_play;
    this.duration = json.duration;
    this.shelfDistanceDelta = json.shelf_distance_delta;

    // Command
    this.commandType = json.command_type;
    this.commandParameters = json.command_parameters;

    // Lifecycle
    this.lifecycleState = json.lifecycle_state;

    // Activate Nav Zone Group
    this.navZoneGroupId = json.nav_zone_group_id;

    // Single Event Options
    this.scheduledDate = json.scheduled_date && new Date(json.scheduled_date);

    // Recurring Event Options
    this.rruleString = json.rrule;

    this.parseRRule();

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      created_by_id: this.createdById,
      updated_by_id: this.updatedById,
      event_type: this.eventType,
      recurrence_type: this.recurrenceType,
      duration: this.duration,
      shelf_distance_delta: this.shelfDistanceDelta,
      store_schedule_id: this.storeScheduleId,
      play_id: this.playId,
      play_outputs: this.playOutputs,
      nav_zone_group_id: this.navZoneGroupId,
      after_play: this.afterPlay,
      command_type: this.commandType,
      command_parameters: this.commandParameters,
      lifecycle_state: this.lifecycleState,
      scheduled_date: this.scheduledDate,
      rrule: this.rruleString
    });
  }

  parseRRule() {
    if ((this.recurrenceType === RecurrenceType.RECURRING_EVENT) && (!_.isNil(this.rruleString))) {
      const rruleElements = this.rruleString.split('\n');
      this.dtstartElement = _.find(rruleElements, element => _.startsWith(element, 'DTSTART'));
      this.rruleElement = _.find(rruleElements, element => _.startsWith(element, 'RRULE'));
      const exdatesElements = _.filter(rruleElements, element => _.startsWith(element, 'EXDATE'));

      this.rrule = RRule.fromString(`${this.dtstartElement}\n${this.rruleElement}`);

      this.timezone = this.rrule.options.tzid;

      this.firstRecurDate = this.rrule.options.dtstart;
      this.lastRecurDate = this.rrule.options.until;

      if (exdatesElements) {
        _.forEach(exdatesElements, exdateElement => {
          const dates = exdateElement.split(':')[1].split(',');

          _.forEach(dates, date => {
            this.addExcludedDate(momentTz.tz(date, this.timezone).toDate());
          });
        });
      }

      if ((this.rrule.options.freq === RRule.DAILY) && (this.rrule.options.byhour.length > 1)) {
        this.rrule.options.freq = RRule.HOURLY;
      }

      if (_.isNil(this.rrule.options.byweekday)) {
        this.rrule.options.byweekday = [0, 1, 2, 3, 4, 5, 6];
      }

      this.frequencyType = this.rrule.options.freq === RRule.DAILY ? FrequencyType.DAILY : FrequencyType.HOURLY;
      this.daysOfWeek = this.getJSWeekdays(this.rrule.options.byweekday);
      this.startTime = '' + ('0' + _.first(this.rrule.options.byhour)).slice(-2) + ':' + ('0' + this.rrule.options.byminute).slice(-2);
      if (this.frequencyType === FrequencyType.HOURLY) {
        this.endTime = '' + ('0' + _.last(this.rrule.options.byhour)).slice(-2) + ':' + ('0' + this.rrule.options.byminute).slice(-2);
      }

      this.generateFullCalendarRRule();
    }
  }

  generateRRule(timezone: string) {
    if (this.recurrenceType === RecurrenceType.RECURRING_EVENT) {
      const rrule = new RRule({
        freq: this.frequencyType === FrequencyType.DAILY ? RRule.DAILY : RRule.HOURLY,
        byweekday: this.getRRuleWeekdays(),
        tzid: timezone,
        dtstart: this.firstRecurDate,
        until: this.lastRecurDate && DateTime.fromJSDate(this.lastRecurDate).toUTC(),
        byminute: this.getMinutes(),
        byhour: this.getHours(),
        bysecond: 0
      });

      this.rruleString = rrule.toString();

      if (this.excludedDates.length !== 0) {
        const tzid = RRule.fromString(this.rruleString).options.tzid;
        let exdateString = `EXDATE;TZID=${tzid}:`;
        const exdates = [];

        _.forEach(this.excludedDates, date => {
          const moment = momentTz.tz(date, tzid);
          exdates.push(moment.format('YYYYMMDD[T]HHmmSS'));
        });

        exdateString = exdateString.concat(exdates.join());
        this.rruleString = this.rruleString.concat(`\n${exdateString}`);
      }

      this.generateFullCalendarRRule();
    }
    else {
      this.rruleString = null;
    }
  }

  generateFullCalendarRRule() {
    if (!this.dtstartElement) {
      this.parseRRule();
    }
    const dateString = _.last(this.dtstartElement.split(':'));
    let rruleString = this.rruleElement;

    if (this.lastRecurDate) {
      const storeMoment = momentTz.tz(this.lastRecurDate, this.timezone);
      rruleString = _.replace(rruleString, /UNTIL=\d{8}T\d{6}/, 'UNTIL=' + storeMoment.format('YYYYMMDD[T]HHmmSS'));
    }

    this.rruleFCString = `DTSTART:${dateString}\n${rruleString}`;
    this.rruleFC = RRule.fromString(this.rruleFCString);
  }

  getFullCalendarRRuleString() {
    return this.rruleFCString;
  }

  addExcludedDate(date: Date) {
    if (!this.containsExcludedDate(date)) {
      this.excludedDates.push(date);
    }
  }

  removeExcludedDate(date: Date) {
    _.remove(this.excludedDates, excludedTime => excludedTime.getTime() === date.getTime());
  }

  containsExcludedDate(date: Date): boolean {
    return !!_.find(this.excludedDates, excludedTime => excludedTime.getTime() === date.getTime());
  }

  isSeriesStart(date: Date): boolean {
    let isSeriesStart = false;

    if ((this.recurrenceType === RecurrenceType.RECURRING_EVENT) && this.firstRecurDate) {
      const firstInstance = this.rruleFC.after(this.firstRecurDate, true);

      if (firstInstance) {
        // This 'correction' is due to the fact that RRule returns all dates as UTC but
        // intended to be interpreted as local time.  https://github.com/jakubroztocil/rrule
        const firstInstanceCorrected = DateTime.fromJSDate(firstInstance)
          .toUTC()
          .setZone(this.timezone, { keepLocalTime: true })
          .toJSDate();

        isSeriesStart = firstInstanceCorrected.getTime() === date.getTime();
      }
    }

    return isSeriesStart;
  }

  isSeriesEnd(date: Date): boolean {
    let isSeriesEnd = false;

    if ((this.recurrenceType === RecurrenceType.RECURRING_EVENT) && this.firstRecurDate && this.lastRecurDate) {
      const lastInstance = this.rruleFC.before(momentTz.tz(this.lastRecurDate, this.timezone).add(1, 'days').toDate(), true);

      if (lastInstance) {
        // This 'correction' is due to the fact that RRule returns all dates as UTC but
        // intended to be interpreted as local time.  https://github.com/jakubroztocil/rrule
        const lastInstanceCorrected = DateTime.fromJSDate(lastInstance)
          .toUTC()
          .setZone(this.timezone, { keepLocalTime: true })
          .toJSDate();

        isSeriesEnd = lastInstanceCorrected.getTime() === date.getTime();
      }
    }

    return isSeriesEnd;
  }

  getHours(): number[] {
    const hours: number[] = [];
    const startHour = Number(_.split(this.startTime, ':')[0]);
    const startMinutes = Number(_.split(this.startTime, ':')[1]);

    const endTime = this.frequencyType === FrequencyType.HOURLY ? this.endTime : this.startTime;
    const endHour = Number(_.split(endTime, ':')[0]);
    const endMinutes = Number(_.split(endTime, ':')[1]);

    for (let hour = startHour; hour <= (endHour < startHour ? startHour : endHour); hour++) {
      if ((hour === endHour) && (startHour !== endHour)) {
        if (startMinutes <= endMinutes) {
          hours.push(hour);
        }
      }
      else {
        hours.push(hour);
      }
    }

    return hours;
  }

  getMinutes(): number {
    return Number(_.split(this.startTime, ':')[1]);
  }

  getRRuleWeekdays(): Weekday[] {
    const weekdays = [];
    _.forEach(this.daysOfWeek, day => weekdays.push(this.getRRuleWeekday(day)));
    return weekdays;
  }

  getRRuleWeekday(javascriptWeekday: number) {
    switch (javascriptWeekday) {
      case 0:
        return RRule.SU;
      case 1:
        return RRule.MO;
      case 2:
        return RRule.TU;
      case 3:
        return RRule.WE;
      case 4:
        return RRule.TH;
      case 5:
        return RRule.FR;
      case 6:
        return RRule.SA;
    }
  }

  getJSWeekdays(rruleWeekdays: number[]): number[] {
    const weekdays = [];
    _.forEach(rruleWeekdays, day => weekdays.push(this.getJSWeekday(day)));
    return weekdays;
  }

  getJSWeekday(rruleWeekday: number) {
    switch (rruleWeekday) {
      case RRule.SU.weekday:
        return 0;
      case RRule.MO.weekday:
        return 1;
      case RRule.TU.weekday:
        return 2;
      case RRule.WE.weekday:
        return 3;
      case RRule.TH.weekday:
        return 4;
      case RRule.FR.weekday:
        return 5;
      case RRule.SA.weekday:
        return 6;
    }
  }

  static getPlayColor(play: Play) {
    let color = '#FFFFFF';

    if (!_.isNil(play)) {
      switch (play.playType) {
        case Play.TYPE_INSIGHT:
          color = '#607D8B';
          break;
        case Play.TYPE_INSPECT:
          color = '#673AB7';
          break;
        case Play.TYPE_FOCUS:
          color = '#32965f';
          break;
      }
    }

    return color;
  }

  static getEventColor(event: StoreScheduleEvent, play: Play = null) {
    let eventColor;

    switch (event.eventType) {
      case EventType.PLAY:
        eventColor = StoreScheduleEvent.getPlayColor(play);
        break;
      case EventType.COMMAND:
        eventColor = '#438aff';
        break;
      case EventType.LIFECYCLE_STATE:
        eventColor = '#FF5722';
        break;
      case EventType.ACTIVATE_NAV_ZONE_GROUP:
        eventColor = '#D4A012';
        break;
    }

    return eventColor;
  }

  static getPlayClassName(play: Play) {
    let className = '';

    if (!_.isNil(play)) {
      switch (play.playType) {
        case Play.TYPE_INSIGHT:
          className = 'play-insight';
          break;
        case Play.TYPE_INSPECT:
          className = 'play-inspect';
          break;
        case Play.TYPE_FOCUS:
          className = 'play-focus';
          break;
      }
    }

    return className;
  }

  static getClassName(event: StoreScheduleEvent, play: Play = null) {
    let className = '';

    switch (event.eventType) {
      case EventType.PLAY:
        className = StoreScheduleEvent.getPlayClassName(play);
        break;
      case EventType.COMMAND:
        className = 'command';
        break;
      case EventType.LIFECYCLE_STATE:
        className = 'lifecycle-state';
        break;
      case EventType.ACTIVATE_NAV_ZONE_GROUP:
        className = 'nav-zone-group';
        break;
    }

    return className;
  }
}
