import { AbstractApiModel } from './abstract-api-model';

export class ManufacturingFacility extends AbstractApiModel<ManufacturingFacility> {
  name: string;

  deserialize(json: any): this {
    this.name = json.name;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      name: this.name
    });
  }
}
