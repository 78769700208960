import { AbstractApiModel } from './abstract-api-model';

export class FmcgEntitlement extends AbstractApiModel<FmcgEntitlement> {
  retail_organization_id: number;
  fmcg_organization_id: number;
  name: string;

  deserialize(json: any): this {
    this.retail_organization_id = json.retail_organization_id;
    this.fmcg_organization_id = json.fmcg_organization_id;
    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      retail_organization_id: this.retail_organization_id,
      fmcg_organization_id: this.fmcg_organization_id
    });
  }
}
