import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MapComponent } from '../map.component';
import { Map } from '../../models/map';

@Component({
  selector: 'bt-map-select-controls',
  templateUrl: './map-select-controls.component.html',
  styleUrls: ['./map-select-controls.component.scss']
})
export class MapSelectControlsComponent implements OnInit {
  @Input() mapComponent: MapComponent;
  @Input() maps: Map[] = [];

  selectedMap: Map;

  @Output() change: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    this.selectedMap = this.maps.find(map => map.mapType == 'low');
  }

  changeMap(map) {
    this.selectedMap = map;
    this.mapComponent.setMap(map);
    this.change.emit(map);
  }

}
