import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { LifecycleStateTransition } from '../models/lifecycle-state-transition';
import { filter, map, tap } from 'rxjs/operators';
import { Robot } from '../models/robot';
import * as _ from 'lodash';

@Injectable()
export class LifecycleService extends ApiService {

  /**
   * Fetch Lifecycle Transitions for a robot
   */
  getLifecycleTransitions(robotId: number, params?: any): Observable<LifecycleStateTransition[]> {
    return this.list(LifecycleStateTransition, `robots/${robotId}/lifecycle_state_transitions`, params);
  }

  getLatestLifecycleTransitions(params?: any): Observable<LifecycleStateTransition[]> {
    return this.list(LifecycleStateTransition, `lifecycle_state_transitions`, params);
  }

  /**
   * Create Lifecycle Transition
   */
  createLifecycleTransition(robotId: number, transition: LifecycleStateTransition): Observable<LifecycleStateTransition> {
    return this.create(transition, `robots/${robotId}/lifecycle_state_transitions`);
  }

  /**
   * Update Lifecycle Transition
   */
  updateLifecycleTransition(lifecycleTransition: LifecycleStateTransition): Observable<LifecycleStateTransition> {
    return this.update(lifecycleTransition, `lifecycle_state_transitions/${lifecycleTransition.id}`);
  }

  /**
   * Open websocket connection to listen for lifecycle state transitions created for a robot
   */
  transitionCreated(robotId): Observable<LifecycleStateTransition> {
    return this.channel(LifecycleStateTransition, 'LifecycleStateTransitionCreatedChannel', { robot_id: robotId }).pipe(
      tap(() => this.deleteCachedRequests([`robots/${robotId}/lifecycle_state_transitions`])));
  }

  /**
   * Fetch Allowed Lifecycle States
   */
  getAllowedLifecycleStates(params: any = {}): Observable<any> {
    return this.http.get(this.url('lifecycle_state_names'), { params: ApiService.params(params) }).pipe(
      filter(response => response != null),
      map(response => response));
  }

  /**
   * Get all Lifecycle state names
   */
  getLifecycleStateNames(): string[] {
    return [
      'manufacturing',
      'pre_install',
      'install',
      'post_install',
      'first_week',
      'fully_operational',
      'hold_for_service',
      'service',
      'maintenance',
      'level_3_support',
      'retired',
      'store_hold',
      'depot',
      'pilot',
      'internal_test',
      'post_install_validation'
    ];
  }

  addLastLifecycleStateTransitionReason(robots: Robot[]): Observable<LifecycleStateTransition[]> {
    const robotsById = _.keyBy(robots, 'id');
    const ids = _.uniq(robots.map(robot => robot.id)).sort();
    return this.collectObservable(_.chunk(ids, 200), (idChunk) => this.getLatestLifecycleTransitions({ 'robot_ids[]': idChunk, latest_only: true }).pipe(
        tap((transitions: LifecycleStateTransition[]) => {
          const lookup = _.keyBy(transitions, 'robotId');
          idChunk.forEach(robotId => {
            const robot = robotsById[robotId];
            robot.lifecycleStateReason = Object.keys(lookup).includes(robot.id.toString()) ? lookup[robot.id].reason : 'No lifecycle transition found';
          });
        })
      ));
  }

  getImageSetStatuses(robotId): Observable<any> {
    const params = {robot_id: robotId}
    return this.http.get(this.url('robot_software_image_sets/image_set_statuses'), { params: ApiService.params(params) }).pipe(
      filter(response => response != null),
      map(response => response));
  }
}
