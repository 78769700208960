import { AbstractApiModel } from './abstract-api-model';

export class SoftwareImageSet extends AbstractApiModel<SoftwareImageSet> {
  setName: string;
  manifest: any;

  deserialize(json: any): this {
    this.setName = json.set_name;
    this.manifest = json.manifest;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      set_name: this.setName
    });
  }
}
