import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VerificationTask } from '../models/verification-task';
import { ApiService } from './api.service';
import { VerificationInstance } from '../models/verification-instance';
import { VerificationPlan } from '../models/verification-plan';
import { VerificationFixtureSet } from '../models/verification-fixture-set';
import { HasVerificationFixtureSet } from '../models/interfaces/has-verification-fixture-set';
import { VerificationTestSuite } from '../models/verification-test-suite';

@Injectable()
export class VerificationService extends ApiService {

  /**
   * Find or create plans for a given robot id.  If plan_type is not provided in params will default to manufacturing
   */
  findOrCreateVerificationPlan(robotId: number, params: any): Observable<VerificationPlan[]> {
    return this.http.post(this.url(`robots/${robotId}/verification_plans/find_or_create`), params).pipe(
      map((response: any[]) => response.map(plan => new VerificationPlan().deserialize(plan)))
    );
  }

  /**
   * Create a verification Plan for a robot
   */
  createVerificationPlan(verificationPlan: VerificationPlan, robotId: number): Observable<VerificationPlan> {
    return this.create(new VerificationPlan(), `robots/${robotId}/verification_plans`);
  }

  /**
   * Get the verification plan for a robot
   */
  getVerificationPlans(params: any): Observable<VerificationPlan[]> {
    return this.list(VerificationPlan, `verification_plans`, params);
  }

  /**
   * Get the verification plan for a robot
   */
  getVerificationPlan(planId: number, robotId: number): Observable<VerificationPlan> {
    return this.get(VerificationPlan, `robots/${robotId}/verification_plans/${planId}`);
  }

  updateVerificationPlan(plan: VerificationPlan): Observable<VerificationPlan> {
    return this.update(plan, `robots/${plan.robotId}/verification_plans/${plan.id}`);
  }

  /**
   * Reset verification plan for a robot
   */
  resetVerificationPlan(robotId: number, planId: number): Observable<VerificationPlan> {
    return this.update(new VerificationPlan(), `robots/${robotId}/verification_plans/${planId}/reset`);
  }

  /**
   * Complete verification plan for a robot
   */
  completeVerificationPlan(robotId: number, planId: number): Observable<VerificationPlan> {
    return this.update(new VerificationPlan(), `robots/${robotId}/verification_plans/${planId}/complete`);
  }

  getVerificationInstances(params: any): Observable<VerificationInstance[]> {
    return this.list(VerificationInstance, 'verification_instances', params);
  }

  getVerificationInstance(id: number): Observable<VerificationInstance> {
    return this.get(VerificationInstance, `verification_instances/${id}`);
  }

  beginVerificationInstance(instance: VerificationInstance): Observable<VerificationInstance> {
    return this.update(instance, `verification_instances/${instance.id}/begin`);
  }

  resetVerificationInstance(instance: VerificationInstance): Observable<VerificationInstance> {
    return this.update(instance, `verification_instances/${instance.id}/reset`);
  }

  verificationInstancePass(instance: VerificationInstance): Observable<VerificationInstance> {
    return this.update(instance, `verification_instances/${instance.id}/pass`);
  }

  verificationInstanceFail(instance: VerificationInstance): Observable<VerificationInstance> {
    return this.update(instance, `verification_instances/${instance.id}/fail`);
  }

  /**
   * Open websocket connection to listen for verification instance updated
   */
  verificationInstanceUpdated(id: number): Observable<VerificationInstance> {
    return this.channel(VerificationInstance, 'VerificationInstanceUpdatedChannel', { id: id }).pipe(
      tap(() => this.deleteCachedRequests('verification_instances')));
  }

  /**
   * Get verification fixture sets for a manufacturing facility
   */
  getVerificationFixtureSets(manufacturingFacilityId: number): Observable<VerificationFixtureSet[]> {
    return this.list(VerificationFixtureSet, `/manufacturing_facilities/${manufacturingFacilityId}/fixture_sets`);
  }

  /**
   * Get current and initial verification fixture sets for a robot
   */
  getRobotsVerificationFixtureSets(robot_id): Observable<VerificationFixtureSet[]> {
    return this.list(VerificationFixtureSet, `/manufacturing_facilities/robot_fixture_sets`, { robot_id: robot_id });
  }

  /**
   * Get verification fixture set for id
   */
  getVerificationFixtureSet(verificationFixtureSetId: number): Observable<VerificationFixtureSet> {
    return this.get(VerificationFixtureSet, `verification_fixture_sets/${verificationFixtureSetId}`);
  }

  /**
   * Sets value for verificationFixtureSet on a model which has an verificationFixtureSet field
   */
  addVerificationFixtureSet(model: HasVerificationFixtureSet): Observable<VerificationFixtureSet> {
    return this.getVerificationFixtureSet(model.verificationFixtureSetId).pipe(tap(set => model.verificationFixtureSet = set));
  }

  /**
   * Get the verification test suites
   */
  getVerificationTestSuites(params: any): Observable<VerificationTestSuite[]> {
    return this.list(VerificationTestSuite, `verification_test_suites`, params);
  }

  /**
   * Get verification test suite
   */
  getVerificationTestSuite(id: number): Observable<VerificationTestSuite> {
    return this.get(VerificationTestSuite, `verification_test_suites/${id}`);
  }

  /**
   * Creates a VerificationTestSuite
   */
  createVerificationTestSuite(verificationTestSuite: VerificationTestSuite): Observable<VerificationTestSuite> {
    return this.create(verificationTestSuite, 'verification_test_suites');
  }

  /**
   * Update VerificationTestSuite
   */
  updateVerificationTestSuite(verificationTestSuite: VerificationTestSuite): Observable<VerificationTestSuite> {
    return this.update(verificationTestSuite, `verification_test_suites/${verificationTestSuite.id}`);
  }

  /**
   * Get the verification tasks
   */
  getVerificationTasks(params: any): Observable<VerificationTask[]> {
    return this.list(VerificationTask, `verification_tasks`, params);
  }

  /**
   * Get verification plan results
   */
  getVerificationFile(robotId: number): Observable<any> {
    return this.http.get(this.url(`robots/${robotId}/download_verification_plan`), {
      responseType: 'json'
    }).pipe(catchError((err) => this.handleError(err)));
  }

}
