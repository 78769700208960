import { AbstractApiModel } from './abstract-api-model';

export class VerificationFixtureSet extends AbstractApiModel<VerificationFixtureSet> {
  name: string;
  manufacturingFacilityId: number;

  deserialize(json: any): this {
    this.name = json.name;
    this.manufacturingFacilityId = json.manufacturing_facility_id;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      name: this.name,
      manufacturing_facility_id: this.manufacturingFacilityId
    });
  }
}