import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';
import { HasStore } from '@ng-cloud/badger-core/models/interfaces/has-store';
import { Store } from '@ng-cloud/badger-core/models/store';
import { NavZoneGroupVersion } from '@ng-cloud/badger-core/models/nav-zone-group-version';
import { NavZone } from '@ng-cloud/badger-core';

export class NavZoneGroup extends AbstractApiModel<NavZoneGroup> implements HasStore {
  name: string;
  description: string;
  schedule: string;
  isActive: boolean;
  storeId: number;
  currentVersionId: number;
  navZoneGroupVersion: NavZoneGroupVersion;
  navZoneGroupVersions: NavZoneGroupVersion[];

  store: Store;
  editable = true;

  getNavZones(): NavZone[] {
    return this.navZoneGroupVersion.navZones;
  }

  deserialize(json: any): this {
    this.name = json.name;
    this.description = json.description;
    this.schedule = json.schedule;
    this.isActive = json.is_active;
    this.storeId = json.store_id;
    this.currentVersionId = json.current_version_id;
    this.navZoneGroupVersion = new NavZoneGroupVersion().deserialize(json.nav_zone_group_version);

    if (json.nav_zone_group_versions) {
      this.navZoneGroupVersions = json.nav_zone_group_versions.map(version => {
        new NavZoneGroupVersion().deserialize(version);
      });
    }

    return super.deserialize(json);
  }

  serialize(): any {
    const attrs = {
      name: this.name,
      description: this.description,
      schedule: this.schedule,
      is_active: this.isActive,
      store_id: this.storeId
    };

    if (this.navZoneGroupVersion) {
      attrs['nav_zone_group_version'] = this.navZoneGroupVersion.serialize();
    }

    return Object.assign(super.serialize(), attrs);
  }
}
