import { filter, takeWhile, tap } from 'rxjs/operators';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { PlayExecution } from '@ng-cloud/badger-core/models/play-execution';
import { Robot } from '@ng-cloud/badger-core/models/robot';
import { Map } from '@ng-cloud/badger-core/models/map';
import { MapComponent } from '@ng-cloud/badger-core/map/map.component';
import { HeartbeatGrouping } from '@ng-cloud/badger-core/map/groupings/heartbeat-grouping';
import { CaptureEventGrouping } from '@ng-cloud/badger-core/map/groupings/capture-event-grouping';
import { RouteGrouping } from '@ng-cloud/badger-core/map/groupings/route-grouping';
import { StoreService } from '@ng-cloud/badger-core/services/store.service';
import { RobotService } from '@ng-cloud/badger-core/services/robot.service';
import { CaptureEventReviewStatus } from '@ng-cloud/badger-core/models/capture-event';

@Component({
  selector: 'bt-play-execution-map',
  templateUrl: './play-execution-map.component.html',
  styleUrls: ['./play-execution-map.component.scss']
})
export class PlayExecutionMapComponent implements OnInit, OnDestroy, OnChanges {
  @Input() id: number;
  @Input() size: string;
  @Input() zoom: string | number;
  playExecution: PlayExecution;
  robot: Robot;
  storeMap: Map;

  mapComponent: MapComponent;
  subscriptions: Subscription[] = [];
  alive = true;

  heartbeatGrouping: HeartbeatGrouping = new HeartbeatGrouping({
    hasFirstColor: true,
    hasLastColor: true
  });
  captureEventGrouping: CaptureEventGrouping = new CaptureEventGrouping();
  routeGrouping: RouteGrouping = new RouteGrouping([this.heartbeatGrouping, this.captureEventGrouping], {
    strokeWidth: 6,
    stroke: 'royalblue'
  });

  constructor(
    protected storeService: StoreService,
    protected robotService: RobotService
  ) {
    this.routeGrouping.changed().pipe(
      takeWhile(() => this.alive))
      .subscribe(() => this.mapComponent && this.mapComponent.render());
  }

  ngOnInit() {
    this.initialize();
  }

  initialize() {
    this.robotService.getPlayExecution(this.id).subscribe(playExecution => {

      if (!this.playExecution) {
        this.storeService.getMap(playExecution.storeId).subscribe(map => {
          this.heartbeatGrouping.setStoreMap(map);
          this.captureEventGrouping.setStoreMap(map);
          this.storeMap = map;
        });
      }

      this.playExecution = playExecution;

      this.robotService.getRobot(this.playExecution.robotId).pipe(
        tap(robot => this.storeService.addStore(robot).subscribe()))
        .subscribe(robot => {
          this.robot = robot;
          this.createSubscriptions();
        });

      this.robotService.getHeartbeats(playExecution.robotId,
        { created_after: playExecution.startTime, created_before: playExecution.endTime ? playExecution.endTime : new Date() })
        .subscribe(heartbeats => {
          this.playExecution.heartbeats = heartbeats;
          this.heartbeatGrouping.setData(heartbeats);
        });

      this.robotService.getCaptureEvents(playExecution.id).subscribe(captureEvents => {
        this.playExecution.captureEvents = captureEvents;
        this.captureEventGrouping.setData(captureEvents);
      });
    });
  }

  ngOnDestroy() {
    this.destroySubscriptions();
    this.alive = false;
    this.heartbeatGrouping.destroy();
  }

  destroySubscriptions() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngOnChanges() {
    this.destroySubscriptions();
    this.initialize();
  }

  // Initialize reactive layers and add any live subscriptions
  onMapLoad(mapComponent: MapComponent) {
    this.mapComponent = mapComponent;
    this.mapComponent.addGrouping(this.routeGrouping);
    this.mapComponent.addGrouping(this.captureEventGrouping);
    this.mapComponent.addLayer(this.captureEventGrouping.getLayer(CaptureEventReviewStatus.suppressed));
    this.mapComponent.render();
  }

  createSubscriptions() {
    this.subscriptions.push(
      this.robotService.playExecutionUpdated(this.playExecution.robotId).pipe(
        takeWhile(() => this.alive),
        filter(playExecution => this.playExecution.id == playExecution.id))
        .subscribe(playExecution => _.merge(this.playExecution, playExecution))
    );

    this.subscriptions.push(
      this.robotService.heartbeats(this.playExecution.robotId).pipe(
        takeWhile(() => this.alive && !this.playExecution.endTime))
        .subscribe(heartbeat => this.heartbeatGrouping.addData(heartbeat))
    );

    this.subscriptions.push(
      this.robotService.captureEventCreated(this.playExecution.id).pipe(
        takeWhile(() => this.alive))
        .subscribe(captureEvent => this.captureEventGrouping.addData(captureEvent))
    );

    this.subscriptions.push(
      this.robotService.captureEventUpdates(this.playExecution.id).pipe(
        takeWhile(() => this.alive))
        .subscribe(captureEvent => this.captureEventGrouping.replaceData(captureEvent))
    );
  }
}
