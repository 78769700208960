import { Injectable } from '@angular/core';
import { RobotService } from '@ng-cloud/badger-core/services/robot.service';
import { Pose, RotationType } from '@ng-cloud/badger-core/models/pose';
import { Command } from '@ng-cloud/badger-core/models/command';
import { Observable } from 'rxjs';

@Injectable()
export class CommandService {

  constructor(
    protected robotService: RobotService
  ) {
  }

  issueCommand(robotId: number, commandType: string, parameters: any = {}): Observable<Command> {
    const command = new Command().assign({ commandType: commandType, parameters: parameters });
    return this.robotService.createCommand(robotId, command);
  }

  setInitialPose(robotId: number, pose: Pose): Observable<Command> {
    const rotation = pose.getRotation(RotationType.Quaternion);
    return this.issueCommand(robotId, 'initial_pose', {
      x: pose.x,
      y: pose.y,
      qz: rotation.qZ,
      qw: rotation.qW
    });
  }

  sendNavGoal(robotId: number, pose: Pose): Observable<Command> {
    const rotation = pose.getRotation(RotationType.Quaternion);
    return this.issueCommand(robotId, 'nav_goal', {
      x: pose.x,
      y: pose.y,
      qz: rotation.qZ,
      qw: rotation.qW
    });
  }

  pause(robotId: number): Observable<Command> {
    return this.issueCommand(robotId, 'pause', { timeout: '0' });
  }

  requestVisualizationData(robotId: number, parameterOverrides = {}): Observable<Command> {
    const commandParams = Object.assign({
      single_sample: 'false',
      update_period_seconds: '30',
      timeout_minutes: '3',
      desired_data: 'current_pose, current_nav_goal, front_laser_scan, local_costmap, front_proximity_rgb, back_proximity_rgb, front_depthcam_rgb, front_fisheye_rgb, back_fisheye_rgb'
    }, parameterOverrides);
    return this.issueCommand(robotId, 'get_barviz_data', commandParams);
  }

}
