import { AbstractApiModel } from './abstract-api-model';
import { Robot } from './robot';

export enum EventType {
  PLAY = 'play',
  COMMAND = 'command',
  LIFECYCLE_STATE = 'lifecycle_state',
  ACTIVATE_NAV_ZONE_GROUP = 'activate_nav_zone_group'
}

export enum InsightType {
  HOLES = 'hole_oos',
  WRONGS = 'eOOS',
  PRICE = 'price_mismatch',
  SALE_PRICE = 'sale_price_mismatch'
}

export enum InspectType {
  HAZARD_DETECTION = 'hazard_detection',
  SECURITY_PATROL = 'security_patrol'
}

export enum AfterPlay {
  DOCK = 'dock',
  PARK = 'park'
}

export enum RecurrenceType {
  SINGLE_EVENT = 'single_event',
  RECURRING_EVENT = 'recurring_event'
}

export enum FrequencyType {
  DAILY = 'daily',
  HOURLY = 'hourly'
}

export class StoreSchedule extends AbstractApiModel<StoreSchedule> {
  name: string;
  storeId: number;
  robotId: number;
  status: string;

  robot: Robot;

  deserialize(json: any): this {
    this.name = json.name;
    this.storeId = json.store_id;
    this.robotId = json.robot_id;
    this.status = json.status;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      name: this.name,
      store_id: this.storeId,
      robot_id: this.robotId,
      status: this.status
    });
  }
}
