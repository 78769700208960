<h2 mat-dialog-title>{{isDuplicate ? 'Duplicate Play' : isCreate ? 'New Play' : play.name}}</h2>

<form [formGroup]="formGroup" (ngSubmit)="isCreate ? create() : update()">
  <mat-dialog-content>
    <bt-form-errors></bt-form-errors>

    <bt-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Play Type</mat-label>
        <mat-select formControlName="playType">
          <mat-option *ngFor="let type of playTypes" class="mat-accent" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <bt-field-errors></bt-field-errors>
    </bt-form-field>

    <bt-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput name="name" formControlName="name"/>
      </mat-form-field>
      <bt-field-errors></bt-field-errors>
    </bt-form-field>

    <bt-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <textarea matInput name="description" formControlName="description">
        </textarea>
      </mat-form-field>
      <bt-field-errors></bt-field-errors>
    </bt-form-field>

    <bt-form-field *ngIf="this.formGroup.controls['playType'].value == 'insight'">
      <mat-checkbox name="captureOnly" formControlName="captureOnly">Enable Capture Only</mat-checkbox>
      <br>
    </bt-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-stroked-button [matDialogClose]="">Cancel</button>
    <button mat-flat-button color="primary" type="submit" [disabled]="formGroup.invalid">{{isCreate ? 'Create' : 'Save'}}</button>
  </mat-dialog-actions>
</form>
