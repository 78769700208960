import { AbstractApiModel } from './abstract-api-model';
import { VerificationTask } from './verification-task';
import { CdnFile } from '@ng-cloud/badger-core/models/cdn-file';
import * as _ from 'lodash';

export class VerificationInstance extends AbstractApiModel<VerificationInstance> {
  userId: number;
  planId: number;
  retryInstanceId: number;
  fixtureId: number;
  priority: number;
  status: VerificationInstanceStatus;
  result: VerificationInstanceResult;
  taskName: string;
  message: string;
  diagnosticPassed: boolean;
  startedAt: Date;
  requiresFixture: boolean;

  task: VerificationTask;
  cdnFiles: CdnFile[];

  deserialize(json: any): this {
    this.userId = json.user_id;
    this.planId = json.plan_id;
    this.retryInstanceId = json.retry_instance_id;
    this.status = json.status;
    this.result = json.result;
    this.taskName = json.verification_task;
    this.message = json.message;
    this.diagnosticPassed = json.diagnostic_passed;
    this.startedAt = json.started_at && new Date(json.started_at);
    this.requiresFixture = json['requires_fixture?'];
    this.priority = json.priority;

    if (!_.isEmpty(json.verification_task_def)) {
      this.task = new VerificationTask().deserialize(json.verification_task_def);
    }

    if (json.cdn_files) {
      this.cdnFiles = json.cdn_files.map(file => new CdnFile().deserialize(file));
    }

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      user_id: this.userId,
      plan_id: this.planId,
      retry_instance_id: this.retryInstanceId,
      status: this.status,
      result: this.result,
      message: this.message,
      diagnostic_passed: this.diagnosticPassed,
      started_at: this.startedAt,
      priority: this.priority,
      manufacturing_fixture_configuration_id: this.fixtureId
    });
  }
}

export enum VerificationInstanceStatus {
  pending = 'pending',
  inProgress = 'in_progress',
  complete = 'complete',
  reset = 'reset',
  canceling = 'canceling'
}

export enum VerificationInstanceResult {
  pass = 'pass',
  fail = 'fail',
  error = 'error',
  timeout = 'timeout'
}
