<h2 mat-dialog-title>Run Play</h2>

<form [formGroup]="formGroup" (ngSubmit)="runPlay()">
  <mat-dialog-content fxLayout="column" fxLayoutGap="20px">
    <div *ngIf="play.playType == Play.TYPE_INSIGHT" fxLayout="row">
      <bt-form-field>
        <div fxLayout="column" fxLayoutGap="5px" [formGroup]="$any(formGroup.controls['insightOutputs'])">
          <mat-checkbox name="{{InsightType.HOLES}}" formControlName="{{InsightType.HOLES}}" color="primary">
            Holes
          </mat-checkbox>
          <mat-checkbox name="{{InsightType.WRONGS}}" formControlName="{{InsightType.WRONGS}}" color="primary">
            Wrongs
          </mat-checkbox>
          <mat-checkbox name="{{InsightType.PRICE}}" formControlName="{{InsightType.PRICE}}" color="primary">
            Price
          </mat-checkbox>
          <mat-checkbox name="{{InsightType.SALE_PRICE}}" formControlName="{{InsightType.SALE_PRICE}}"
                        color="primary">Sale Price
          </mat-checkbox>
        </div>
      </bt-form-field>
    </div>

    <div *ngIf="play.playType == Play.TYPE_INSPECT">
      <bt-form-field>
        <mat-form-field color="accent" appearance="outline" subscriptSizing="dynamic">
          <mat-label>Play Type</mat-label>
          <mat-select formControlName="inspectType" required>
            <mat-option value="{{InspectType.HAZARD_DETECTION}}"
                        class="mat-accent">{{InspectType.HAZARD_DETECTION | startCase}}</mat-option>
            <mat-option value="{{InspectType.SECURITY_PATROL}}"
                        class="mat-accent">{{InspectType.SECURITY_PATROL | startCase}}</mat-option>
          </mat-select>
        </mat-form-field>
      </bt-form-field>
    </div>

    <div *ngIf="play.playType == Play.TYPE_INSIGHT" fxLayout="row">
      <bt-form-field>
        <mat-form-field appearance="outline">
          <input matInput formControlName="shelfDistanceDelta"/>
          <mat-label>Shelf Distance Delta (meters)</mat-label>
        </mat-form-field>
      </bt-form-field>
    </div>

    <bt-form-field>
      <mat-form-field color="accent" appearance="outline" subscriptSizing="dynamic">
        <mat-label>After Play</mat-label>
        <mat-select formControlName="afterPlay" required>
          <mat-option value="{{AfterPlay.DOCK}}">{{AfterPlay.DOCK | startCase}}</mat-option>
          <mat-option value="{{AfterPlay.PARK}}">{{AfterPlay.PARK | startCase}}</mat-option>
        </mat-select>
      </mat-form-field>
    </bt-form-field>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-stroked-button [matDialogClose]="">Cancel</button>
    <button mat-flat-button color="primary" type="submit" [disabled]="formGroup.invalid">Run Play</button>
  </mat-dialog-actions>
</form>

