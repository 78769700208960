<mat-menu #appMenu="matMenu" yPosition="above">
  <mat-radio-group [value]="selectedMap.mapType" fxLayout="column">
    <div *ngFor="let map of maps" mat-menu-item>
      <mat-radio-button (change)="changeMap(map)" [value]="map.mapType">
        {{map.mapType | startCase}}
      </mat-radio-button>
    </div>
  </mat-radio-group>
</mat-menu>

<div class="map-control map-select-controls">
  <mat-card>
    <button mat-icon-button matTooltip="Change Displayed Map" [matTooltipShowDelay]="600" [matMenuTriggerFor]="appMenu">
      <mat-icon>map</mat-icon>
    </button>
  </mat-card>
</div>
