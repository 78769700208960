import { AbstractApiModel } from './abstract-api-model';
import { User } from './user';

export class LifecycleStateTransition extends AbstractApiModel<LifecycleStateTransition> {
  previousState: string;
  state: string;
  reason: string;
  createdById: number;
  robotId: number;

  createdBy: User;

  deserialize(json: any): this {
    this.previousState = json.previous_state;
    this.state = json.state;
    this.reason = json.reason;
    this.createdById = json.created_by_id;
    this.robotId = json.robot_id;
    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      state: this.state,
      reason: this.reason
    });
  }
}
