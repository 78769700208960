import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { SoftwareImage } from '../models/software-image';
import { SoftwareImageSet } from '../models/software-image-set';
import { Observable } from 'rxjs';
import { NeuralNetImage } from '../models/neural-net-image';

@Injectable()
export class SoftwareImageService extends ApiService {

  getSnapNames(): Observable<string[]> {
    return this.http.get(this.url('robot_software_images/snap_names')).pipe(
      catchError(err => this.handleError(err)));
  }

  /**
   * Fetch all Robot Software Image by snaps
   */
  getSoftwareImageSnaps(): Observable<any> {
    return this.http.get(this.url('robot_software_images/snaps')).pipe(
      catchError(err => this.handleError(err)));
  }

  /**
   * Fetch software images that meet criteria set by params
   */
  getSoftwareImages(params?: any): Observable<SoftwareImage[]> {
    return this.list(SoftwareImage, '/robot_software_images', params, 0);
  }

  /**
   * Fetch software images that meet criteria set by params
   */
  getSoftwareSetUpgrades(payloadVersion: string, truckVersion: string): Observable<SoftwareImageSet[]> {
    return this.list(SoftwareImageSet, '/robot_software_image_sets/compatible_set_upgrades',
      { payload_version: payloadVersion, truck_version: truckVersion }, 0);
  }

  getSoftwareSetDowngrades(payloadVersion: string, truckVersion: string): Observable<SoftwareImageSet[]> {
    return this.list(SoftwareImageSet, '/robot_software_image_sets/compatible_set_downgrades',
      { payload_version: payloadVersion, truck_version: truckVersion }, 0);
  }

  getNeuralNets(orgId: number, compatible: string): Observable<NeuralNetImage[]> {
    return this.list(NeuralNetImage, '/neural_net_images', { organization_id: orgId, compatible: compatible });
  }

  getNeuralNetsByOrg(orgId: number): Observable<NeuralNetImage[]> {
    return this.list(NeuralNetImage, '/neural_net_images', { organization_id: orgId });
  }

  /**
   * Update 1 or more robots with given params
   *
   * @param robotIds - list of ids for robots that should be updated
   * @param softwareSetName - the set name to upgrade/downgrade to
   * @returns {any}
   */
  massUpdateRobots(robotIds, softwareSetName): any {
    return this.http.post(this.url('/robot_software_image_sets/update_robots'), {
      robot_ids: robotIds,
      software_set_name: softwareSetName
    }).pipe(
      catchError(err => this.handleError(err)));
  }

  /**
   * Update 1 or more robots with given params
   *
   * @param robotIds - list of ids for robots that should be updated
   * @param softwareSetId - the set name to upgrade/downgrade to
   * @param neuralNetId - the id of the neural net to upgrade/downgrade to
   * @param downloadOnly - boolean, determines whether the robot should just download or download & install the images
   * @returns {any}
   */
  massUpdateRobotsSetAndNeural(robotIds, softwareSetId, neuralNetId, downloadOnly): any {
    return this.http.post(this.url('/robot_software_image_sets/update_robots'), {
      robot_ids: robotIds,
      set_id: softwareSetId,
      neural_net_id: neuralNetId,
      download_only: downloadOnly
    }).pipe(
      catchError(err => this.handleError(err)));
  }


  /**
   * Get software images associated with a set id
   */
  getRobotSoftwareImagesBySetId(id: number): Observable<SoftwareImage[]> {
    return this.list(SoftwareImage, `robot_software_image_sets/${id}/associated_images`);
  }
}
