import { Injectable } from '@angular/core';
import { ApiService } from '@ng-cloud/badger-core/services/api.service';
import { ZoneTemplate } from '@ng-cloud/badger-core/models/zone-template';
import { ZoneName } from '@ng-cloud/badger-core/models/zone-name';
import { Observable } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

@Injectable()
export class ZoneService extends ApiService {

  /**
   * Fetch ZoneNames based on params
   */
  getZoneNames(params?: any): Observable<ZoneName[]> {
    return this.list(ZoneName, `zone_names`, params);
  }

  /**
   * Create ZoneName based on params
   */
  createZoneName(zoneName: ZoneName): Observable<ZoneName> {
    return this.create(zoneName, `zone_names`);
  }

  /**
   * Update ZoneName
   */
  updateZoneName(zoneName: ZoneName): Observable<ZoneName> {
    return this.update(zoneName, `zone_names/${zoneName.id}`);
  }

  /**
   * Destroy ZoneName
   */
  deleteZoneName(zoneName): Observable<any> {
    return this.destroy(zoneName, `zone_names/${zoneName.id}`);
  }

  /**
   * Fetch zone templates based on params
   */
  getZoneTemplates(params?: any): Observable<ZoneTemplate[]> {
    return this.list(ZoneTemplate, `zone_templates`, params);
  }

  /**
   * Update ZoneTemplate
   */
  updateZoneTemplate(template: ZoneTemplate): Observable<ZoneTemplate> {
    return this.update(template, `zone_templates/${template.id}`);
  }

  /**
   * Create zone templates for list of zone names and an organization
   */
  batchCreateTemplates(body): Observable<ZoneTemplate[]> {
    return this.http.post<ZoneTemplate[]>(this.url(`zone_templates/batch_create`), body).pipe(
      filter(response => response != null),
      map(result => result.map(json => new ZoneTemplate().deserialize(json))),
      catchError(e => this.handleError(e)));
  }

  /**
   * Destroy Zone Template
   */
  deleteTemplate(zoneTemplate): Observable<any> {
    return this.destroy(zoneTemplate, `zone_templates/${zoneTemplate.id}`);
  }
}
